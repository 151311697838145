import React, { useState } from 'react';
import '../styles/styles_index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FaqsPage = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const faqs = [
    {
      question: "Què és onvaelgovern.cat?",
      answer: `<p>Una eina de consulta, per centralitzar i donar a conèixer d'una manera gràfica on van els membres del govern de la Generalitat.<p>
              <p> A més, el projecte és una versió bàsica de l'eina EstratECO, que permet planificar i fer reporting de l'activitat governamental. </p>
              <p> Si vols conèixer amb més profunditat les dades, o descobrir les possibilitats d'EstratECO, contacta amb nosaltres a <a href="info@femeco.cat" target="_blank"> info@femeco.cat </a> o consulta aquest <a href="https://femeco.cat/index.php/ca/ecos-corner/estrateco" target="_blank"> enllaç.</a> `
    },
    {
      question: "Quines són les fonts de dades?",
      answer: `Les fonts de dades són: <a href="https://govern.cat/gov/agenda" target="_blank">https://govern.cat/gov/agenda</a> i <a href="https://govern.cat/salapremsa/agenda-govern" target="_blank">https://govern.cat/salapremsa/agenda-govern</a>.`
    },

    {
      question: "Quina informació s'inclou a onvaelgovern.cat?",
      answer: `<p>Onvaelgovern.cat inclou tots aquells actes i reunions que figurin a l'agenda oficial dels membres del Govern, excepte aquells que per diferents motius s'han exclòs i que s'expliquen a la pregunta següent.<p>
              <p>Per tant, només inclou allò que els diferents departaments comuniquen a l'agenda oficial, inclosos actes i reunions tant pel territori com a les seus departamentals. També cal esmentar que hi pot haver alguna divergència en el recompte final d'actes perquè s'ha detectat que els diferents departaments especifiquen amb diferent grau de precisió l'agenda del membre de govern, anunciant-ho de forma més general o més detallada.<p>`

    },
    {
      question: "Quina informació no s'inclou a onvaelgovern.cat?",
      answer: `<p>Onvaelgovern.cat no inclou les reunions del Consell Executiu ni les sessions parlamentàries amb presència de membres del Govern. S'ha decidit així perquè són actes periòdics que sobredimensionarien la mostra (les sessions del Consell Executiu són setmanals i els Plens al Parlament quinzenals, generalment).</p>
              <p>Tampoc s'inclouen els discursos institucionals, les atencions a mitjans de comunicació o les rodes de premsa que figurin a l'agenda.</p>`

    },
    {
      question: "Com es resol una discrepància entre les dades?",
      answer: `<p>En cas de trobar una discrepància entre les dades, es prioritzarà el que consti a.</p> 
               <p>Per discrepància entenem que consti informació manifestament contradictòria entre les fonts de les dades. Per exemple, que a la mateixa hora consti que el Conseller/a ha estat a dos llocs diferents.</p>`
    },
    {
      question: "Quina és la periodicitat d’actualització de les dades?",
      answer: `Les dades s'actualitzen al dia següent. Per exemple, les dades del dia 1 de gener de 2024 estaran disponibles el dia 2 de gener de 2024.`
    }
  ];

  return (
    <div>
      <header>
        <nav className="custom-navbar">
          <div className="custom-logo-container">
            <div className="custom-logo">
              <a href="/"><img src="assets/logoECO_blanc.png" alt="Logo" /></a>
            </div>
            <div className="custom-menu-icon" id="menuIcon" onClick={toggleMenu}>
              <span className="material-symbols-outlined">menu</span>
            </div>
          </div>
          <div className={`custom-nav-links ${menuOpen ? 'show' : ''}`} id="navLinks">
            <a href="/stats">Explora les dades</a>
            <a href="https://www.femeco.cat/index.php/ca/">Qui som</a>
            <a href="https://femeco.cat/index.php/ca/ecos-corner/estrateco">EstratECO</a>
            <a href="/faqs">FAQ's</a>
            {menuOpen && (
              <span className="custom-close-icon material-symbols-outlined" onClick={toggleMenu}>
                close
              </span>
            )}
          </div>
        </nav>
      </header>

      <main>
        <section className="faq-section">
          <h2>Què és onvaelgovern.cat? Què és EstratECO? D’on surten les dades?... Aquestes i altres preguntes les intentarem resoldre en aquest espai.</h2>
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <h3 className="faq-question">{faq.question}</h3>
              <p className="faq-answer" dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </div>
          ))}
        </section>
      </main>

      <footer className="custom-footer">
        <h6 className='custom-h6'>Copyright © 2024 Estratègia, Comunicació i Oratòria S.L. Tots els drets reservats.</h6>
      </footer>
    </div>
  );
};

export default FaqsPage;
